import React from 'react';
import PropTypes from 'prop-types';
import { toDataURL } from 'qrcode';
import { Popover } from 'react-tiny-popover';

import { toast } from '../toast';
import { ListItemSpinner } from '../Components/Spinner';

function dataURItoBlob (dataURI) {
    const byteString = atob(dataURI.split(',')[1]);  // eslint-disable-line
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });  // eslint-disable-line
}

class QRDropdown extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            loading: true
        };
    }

    componentDidMount () {
        toDataURL(this.props.url, (err, url) => {
            this.setState({
                qrCodeImgUrl: url,
                loading: false
            });
        });
    }

    copyToClipboard = () => {
        if (!window.ClipboardItem || !navigator.clipboard || !navigator.clipboard.write) {
            toast('COPY_UNSUPPORTED_BY_BROWSER');
            Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_not_supported_by_browser').send();
            return;
        }

        this.setState({ loading: true });

        navigator.clipboard.write([
            new ClipboardItem({ 'image/png': dataURItoBlob(this.state.qrCodeImgUrl) })  // eslint-disable-line
        ])
            .then(() => {
                Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_clicked').send();
                toast('COPIED');
            })
            .catch(() => {
                toast('COPY_IMAGE_FAILED');
                Settings.IN_WEB_VIEW && window.HostNotification.event('dialog_manage_links_copy_qr_failed').send();
            })
            .finally(() => this.setState({ loading: false }));
    };

    renderContent = () => {
        const { loading } = this.state;
        const { id } = this.props;

        return (
            <div
                className='qr-wrap'
                data-what='qr-code-popup'
                id={`qr-code-img-${id}`}
            >
                <div className='svg-wrapper'>
                    <img src={this.state.qrCodeImgUrl} />
                </div>
                <div
                    className={`vw-btn vw-btn-secondary relative ${loading ? 'disabled' : ''}`}
                    style={{ padding: '0 20px', minWidth: 72, lineHeight: '18px' }}
                    onClick={this.copyToClipboard}
                    data-what='copy-btn'
                >
                    {gettext('Copy')}
                    {this.state.loading && <ListItemSpinner styles={{ position: 'absolute', right: 3, top: 3 }} />}
                </div>
            </div>
        );
    };

    close = () => this.setState({ open: false });
    open = (e) => {
        this.setState({ open: true });
    };

    render () {
        const popoverProps = {
            isOpen: this.state.open,
            onClickOutside: this.close,
            position: 'bottom',
            content: this.renderContent(),
            containerClassName: 'box-shadow fixed',
            containerStyle: { zIndex: 9999 },
            transitionDuration: 0
        };

        if (this.props.node) {
            const { node } = this.props;
            const { top, left } = node.getBoundingClientRect();

            Object.assign(popoverProps, {
                contentDestination: node,
                contentLocation: {
                    top: top + 32,
                    left: left - 42
                }
            });
        }

        return (
            <Popover {...popoverProps}>
                <div
                    onClick={this.open}
                    className={`icon icon-qr-code-16 ${this.props.togglerClass || ''}`}
                    title={gettext('QR code')}
                    data-what='link-qr-dropdown'
                    ga-action='QR'
                    ga-label='Click_QR_Dropdown'
                />
            </Popover>
        );
    }
}

QRDropdown.propTypes = {
    id: PropTypes.string,
    url: PropTypes.string,
    togglerClass: PropTypes.string,
    node: PropTypes.object
};

export default QRDropdown;
