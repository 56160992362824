import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import React from 'react';
import PropTypes from 'prop-types';

import ErrorList from './ErrorList';
import { prepareErrorSome, PossibleReasons } from './texts';

function Errors ({ uuid, errors, dialog }) {
    const onCancel = () => { dialog.dismiss(uuid); };
    const onSubmit = () => { dialog.close(uuid); };

    return (
        <Dialog>
            <Dialog.Header
                title={gettext('Copying warning')}
                onClose={onCancel}
            />

            <Dialog.Inner>
                <PossibleReasons warning={prepareErrorSome}/>
                <ErrorList errors={errors} />
            </Dialog.Inner>

            <Dialog.Buttons>
                <Button variant='primary' onClick={onSubmit}>{ gettext('Continue') }</Button>
                <Button variant='secondary' onClick={onCancel}>{ gettext('Cancel') }</Button>
            </Dialog.Buttons>
        </Dialog>
    );
}

Errors.propTypes = {
    errors: PropTypes.object,
    uuid: PropTypes.string.isRequired,
    dialog: PropTypes.any.isRequired
};

export default Errors;
