/* eslint-disable */
export default {
    folder: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
                height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
            <path fill="none" d="M0,0h24v24H0V0z"/>
            <path d="M5,21c-2.2092,0-4-1.7909-4-4V3h9l1.875,3H19c2.2092,0,4,1.7909,4,4v7c0,2.2091-1.7908,4-4,4H5z"/>
        </svg>
    `,
    'file-default': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0.001h24v24H0V0.001z"/>
        <path d="M6,24c-3.3121-0.0038-5.9961-2.6879-6-6V6c0.0039-3.3121,2.6879-5.9962,6-6h9c4.9683,0.0055,8.9945,4.0317,9,9v9
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2092,1.7908,4,4,4h12c2.2092,0,4-1.7908,4-4V9
            c0.0034-0.207-0.009-0.4139-0.037-0.619h-7.317V2H6C3.7908,2,2,3.7908,2,6z M16.646,6.382h4.848
            c-0.8471-2.0952-2.6519-3.6539-4.848-4.187V6.382z"/>
        </svg>
    `,
    'file-archive': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <rect fill="none" width="24" height="24"/>
        <path d="M6,24c-3.3121-0.0038-5.9962-2.6879-6-6V6c0.0038-3.3121,2.6879-5.9962,6-6h12c3.3121,0.0038,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4h-3v3
            c0,1.1046-0.8954,2-2,2h-2C9.8954,7,9,6.1046,9,5V2H6C3.7908,2,2,3.7909,2,6z M11,3c0,0.5523,0.4477,1,1,1s1-0.4477,1-1
            s-0.4477-1-1-1S11,2.4477,11,3z M11.491,20.5V19H9v-2h2.5v-2H9v-2h2.5v-2H9V9h3.5v2H15v2h-2.5v2H15v2h-2.5v1.5H15v2H11.491z"/>
        </svg>
    `,
    'file-image': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0038-5.9961-2.6879-6-6V6c0.0039-3.3121,2.6879-5.9962,6-6h12c3.3121,0.0038,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2092,1.7908,4,4,4h12c2.2092,0,4-1.7908,4-4V6c0-2.2092-1.7908-4-4-4H6
            C3.7908,2,2,3.7908,2,6z M5.679,17.322l4.882-4.882l2.184,2.173l2.7-3.393l4.881,6.1L5.679,17.322z M8.547,9.922
            C7.1956,9.9187,6.1027,8.8204,6.106,7.469s1.1016-2.4443,2.453-2.441C9.9099,5.0308,11.0027,6.1281,11,7.479
            c0,0.0007,0,0.0013,0,0.002C10.9973,8.83,9.903,9.922,8.554,9.922H8.547z"/>
        </svg>
    `,
    'file-pts': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0039-5.9961-2.6879-6-6V6c0.0039-3.3121,2.6879-5.9961,6-6h12c3.3121,0.0039,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9961-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M7.05,16.95c-2.7338-2.7335-2.7341-7.1656-0.0005-9.8995C7.0496,7.0503,7.0498,7.05,7.05,7.0499
            c2.7335-2.7338,7.1656-2.734,9.8995-0.0005c0.0002,0.0001,0.0004,0.0004,0.0005,0.0005c2.7338,2.7335,2.7341,7.1656,0.0005,9.8995
            c-0.0002,0.0001-0.0004,0.0004-0.0005,0.0005c-1.3115,1.3148-3.093,2.0526-4.95,2.05C10.1429,19.0026,8.3615,18.2648,7.05,16.95z
            M7.223,14.7269c0.243,0.4252,0.5416,0.8159,0.888,1.162c1.3303,1.33,3.2479,1.8878,5.084,1.479l-4.307-4.307L7.223,14.7269z
            M13.061,15.111l1.666,1.666c0.4255-0.2424,0.8163-0.541,1.162-0.8879c1.3296-1.3301,1.8875-3.2472,1.479-5.083L13.061,15.111z
            M9.95,12l2.05,2.051l2.05-2.051l-2.05-2.05L9.95,12z M8.111,8.111c-1.3296,1.3304-1.8871,3.248-1.478,5.084l4.306-4.307
            l-1.666-1.666C8.8478,7.4652,8.457,7.7643,8.111,8.111L8.111,8.111z M10.806,6.6329l4.306,4.306l1.666-1.665
            c-0.2427-0.4255-0.5414-0.8167-0.888-1.163C14.8597,7.0773,13.4595,6.4974,12,6.5c-0.402,0.0009-0.8027,0.0454-1.195,0.1331
            L10.806,6.6329z"/>
        </svg>
    `,
    'file-pdf': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0038-5.9961-2.6879-6-6V6c0.0039-3.3121,2.6879-5.9961,6-6h12c3.3121,0.0039,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2092,1.7908,4,4,4h12c2.2092,0,4-1.7908,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M5.583,19.353C5.2565,18.9841,5.1321,18.4783,5.25,18c0.233-0.956,1.417-1.879,3.617-2.82
            c0.4622-0.8362,0.881-1.6957,1.255-2.575c0.333-0.788,0.642-1.5861,0.9-2.326c-0.76-1.853-0.9-3.744-0.337-4.665
            c0.2023-0.3556,0.5648-0.5906,0.972-0.63c0.4579-0.089,0.926,0.0955,1.2,0.473c0.332,0.463,0.641,1.459-0.442,4.759
            c0.3189,0.6792,0.7246,1.3142,1.207,1.889c0.4174,0.4862,0.8979,0.9143,1.429,1.2729c0.6542-0.0939,1.3141-0.1429,1.975-0.147
            c1.6,0,2.57,0.338,2.869,1c0.2092,0.3792,0.1992,0.8412-0.026,1.211c-0.3137,0.418-0.8259,0.6378-1.345,0.577
            c-1.3428-0.1049-2.6376-0.5461-3.765-1.283c-0.7548,0.1279-1.5012,0.3016-2.235,0.52c-0.976,0.289-1.919,0.616-2.741,0.949
            c-0.4553,0.8158-0.9761,1.5933-1.557,2.325c-0.695,0.845-1.269,1.238-1.806,1.238C6.0908,19.7711,5.7798,19.6169,5.583,19.353z
            M6.513,18.309c-0.0106,0.0325-0.0106,0.0675,0,0.1c0.3857-0.307,0.7228-0.6704,1-1.078c-0.841,0.542-0.979,0.888-1.001,0.978H6.513
            z M18.521,14.724h0.033c-0.3511-0.1102-0.7161-0.1702-1.084-0.178c0.3406,0.1055,0.6937,0.1653,1.05,0.178H18.521z M10.769,14.455
            c0.448-0.153,0.912-0.3,1.382-0.441c0.347-0.1,0.7-0.2,1.045-0.281l-0.088-0.057l0.192-0.03c-0.2392-0.2189-0.4643-0.4526-0.674-0.7
            c-0.282-0.3344-0.541-0.6875-0.775-1.057l-0.056,0.1639l-0.023-0.054c-0.144,0.37-0.3,0.744-0.453,1.115
            c-0.18,0.426-0.377,0.859-0.586,1.292l0.086-0.036L10.769,14.455z M11.803,6.278c-0.1649,0.3583-0.2203,0.7573-0.159,1.147
            c0.01,0.167,0.029,0.342,0.056,0.52c0.1675-0.54,0.2092-1.1113,0.122-1.67L11.803,6.278z"/>
        </svg>
    `,
    'file-rvt': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0038-5.9962-2.6879-6-6V6c0.0038-3.3121,2.6879-5.9962,6-6h12c3.3121,0.0038,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2092,1.7908,4,4,4h12c2.2092,0,4-1.7908,4-4V6c0-2.2092-1.7908-4-4-4H6
            C3.7908,2,2,3.7908,2,6z M14.792,16.544h-0.046c-0.3713,0.0001-0.7358-0.1001-1.055-0.29l-0.014-0.009
            c-0.3478-0.2336-0.6246-0.5585-0.8-0.939l-1.292-2.491h-1.049v3.593H8.253V6.459h3.444c0.064,0,0.129,0,0.193,0
            c0.8426-0.0012,1.6658,0.253,2.361,0.729h0.005c0.6637,0.4749,1.0469,1.2493,1.022,2.065v0.769
            c0.0054,0.5317-0.171,1.0493-0.5,1.467c-0.2759,0.3483-0.6226,0.6343-1.017,0.839l1.1,2.1c0.0974,0.1918,0.2993,0.3073,0.514,0.294
            h0.005l0.437-0.019v1.607l-0.185,0.077c-0.2536,0.1051-0.5255,0.1591-0.8,0.159L14.792,16.544z M10.539,10.96h1.118
            c0.3611,0.0203,0.7191-0.0773,1.02-0.278c0.2138-0.1629,0.3339-0.4205,0.321-0.689v-0.7c0.0142-0.2682-0.1062-0.5258-0.321-0.687
            c-0.3009-0.2017-0.6592-0.3004-1.021-0.281h-1.117V10.96z"/>
        </svg>
    `,
    'file-text': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0.001h24v24H0V0.001z"/>
        <path d="M6,24c-3.3123-0.0033-5.9967-2.6876-6-6V6c0.0039-3.3121,2.6879-5.9961,6-6h9c4.9683,0.0055,8.9945,4.0317,9,9v9
            c-0.0038,3.3121-2.6879,5.9961-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V9
            c0.0034-0.2067-0.0089-0.4133-0.037-0.618h-7.317V2H6C3.7908,2,2,3.7909,2,6z M16.646,6.3831h4.848
            c-0.847-2.0955-2.6517-3.6545-4.848-4.188V6.3831z M6,18.001v-2h12v2H6z M6,14.001v-2h12v2H6z"/>
        </svg>
    `,
    'file-vgx': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0038-5.9962-2.6879-6-6V6c0.0038-3.3121,2.6879-5.9962,6-6h12c3.3121,0.0038,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9962-6,6H6z M2,6v12c0,2.2091,1.7909,4,4,4h12c2.2091,0,4-1.7909,4-4V6c0-2.2091-1.7909-4-4-4H6
            C3.7909,2,2,3.7909,2,6z M11.647,17.653l-5.23-2.785c-0.2455-0.1293-0.3994-0.3836-0.4-0.661V8.1
            C6.0159,7.792,6.203,7.5144,6.489,7.4l5.23-2.092c0.0887-0.0356,0.1834-0.0539,0.279-0.054c0.0953,0,0.1897,0.0183,0.278,0.054
            L17.507,7.4c0.2858,0.1145,0.4726,0.3921,0.471,0.7v6.277c0.0002,0.284-0.1601,0.5438-0.414,0.671l-5.231,2.615
            c-0.1039,0.0522-0.2187,0.0792-0.335,0.079c-0.1224-0.0004-0.2429-0.0306-0.351-0.088V17.653z M12.747,10.7v5.079l3.73-1.866V9.208
            L12.747,10.7z M7.517,13.755l3.73,1.987V10.7l-3.73-1.492V13.755z M8.787,8.099l3.21,1.285l3.211-1.285L12,6.816L8.787,8.099z"/>
        </svg>
    `,
    'file-vwx': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.312-0.0039-5.9961-2.6879-6-6V6c0.0039-3.3121,2.688-5.9961,6-6h12c3.312,0.0039,5.9961,2.6879,6,6v12
            c-0.0039,3.3121-2.688,5.9961-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M11.333,16.1c-0.1484-0.0261-0.2812-0.108-0.3711-0.229L10.949,15.858l-0.0071-0.016L7.448,8.2571
            c-0.0552-0.0568-0.0715-0.1406-0.042-0.214c0.041-0.088,0.1299-0.1436,0.2271-0.142h1.8c0.1321,0,0.157,0.016,0.27,0.13
            L9.717,8.0441l0.0081,0.017l3.3931,7.6851c0.0547,0.0565,0.071,0.1399,0.042,0.213c-0.0413,0.0878-0.1301,0.1433-0.2271,0.142
            L11.333,16.1z M13.833,13.325l-0.7939-1.785l-0.0081-0.019v-0.343l0.009-0.02l1.3921-3.084c0.0127-0.1006,0.0986-0.1758,0.2-0.175
            h1.8c0.0698-0.0039,0.1348,0.0359,0.1631,0.1c0.0269,0.0939-0.0037,0.1947-0.0779,0.2581l-2.2859,5.0699
            c-0.0198,0.0929-0.1052,0.1566-0.2,0.149c-0.094,0.0061-0.178-0.0583-0.1965-0.1508L13.833,13.325z"/>
        </svg>
    `,
    'file-vcdoc': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3121-0.0039-5.9961-2.6879-6-6V6c0.0039-3.3121,2.6879-5.9961,6-6h12c3.3121,0.0039,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9961-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M8,18.1c-1.6569,0-3-1.3431-3-3v-6.274c0-1.6569,1.3431-3,3-3h8c1.6569,0,3,1.3431,3,3V15.1
            c0,1.6569-1.3431,3-3,3H8z M7,8.825v6.274c0,0.5522,0.4477,1,1,1h8c0.5523,0,1-0.4478,1-1V8.825c0-0.5522-0.4477-1-1-1H8
            C7.4477,7.825,7,8.2727,7,8.825C7,8.8259,7,8.827,7,8.828V8.825z M8,14.974V9h8v4.465h-3v1.512L8,14.974z M9.5,13.474h2v-1.512h3
            v-1.464h-5V13.474z"/>
        </svg>
    `,
    'file-video': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3123-0.0033-5.9967-2.6876-6-6V6c0.0033-3.3124,2.6877-5.9967,6-6h12c3.3123,0.0033,5.9967,2.6876,6,6v12
            c-0.0033,3.3124-2.6877,5.9967-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M9,7l8,5l-8,5V7z"/>
        </svg>
    `,
    'file-panorama': `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M6,24c-3.3123-0.0033-5.9967-2.6876-6-6V6c0.0038-3.3121,2.6879-5.9961,6-6h12c3.3121,0.0039,5.9962,2.6879,6,6v12
            c-0.0038,3.3121-2.6879,5.9961-6,6H6z M2,6v12c0,2.2091,1.7908,4,4,4h12c2.2092,0,4-1.7909,4-4V6c0-2.2091-1.7908-4-4-4H6
            C3.7908,2,2,3.7909,2,6z M6.267,17.248C4.815,16.6641,4,16.097,4,14.677V8.929c0-1.42,0.95-2.0179,2.267-2.571
            c1.8093-0.6492,3.7234-0.9572,5.645-0.908c1.9788-0.0396,3.9492,0.2677,5.822,0.908c1.308,0.538,2.266,1.151,2.266,2.571v5.748
            c0,1.42-0.843,1.9871-2.266,2.571c-1.8073,0.708-3.7462,1.0173-5.684,0.907C10.0801,18.2606,8.11,17.9515,6.267,17.248z
            M12.05,16.155c1.6772,0.1085,3.358-0.1501,4.925-0.7581c0.355-0.1188,0.6895-0.2922,0.991-0.514l-3.384-1.817l-2.77,3.088
            L12.05,16.155z M7.043,8.2001c-0.842,0.355-1,0.5291-1.026,0.572c-0.0135,0.0508-0.0189,0.1035-0.016,0.156v5.748
            c-0.002,0.0671,0.0038,0.1342,0.017,0.2c0.2999,0.2306,0.6385,0.4056,1,0.517c0.7219,0.2954,1.4788,0.4971,2.252,0.6l4.352-4.851
            c0.308-0.343,0.8107-0.431,1.217-0.213l3.166,1.7v-3.7c0.0026-0.0532-0.0031-0.1066-0.017-0.158c-0.011-0.02-0.135-0.2-1.011-0.563
            c-1.6312-0.54-3.3431-0.7961-5.061-0.757C10.2594,7.4026,8.6077,7.6564,7.042,8.2L7.043,8.2001z M7.294,10.7201
            c0-0.9941,0.8058-1.8,1.7999-1.8c0.0001,0,0.0001,0,0.0001,0c0.9941,0,1.8,0.8059,1.8,1.8l0,0c0,0.9941-0.8058,1.8-1.7999,1.8
            c-0.0001,0-0.0001,0-0.0001,0C8.0999,12.5201,7.294,11.7142,7.294,10.7201V10.72V10.7201z"/>
        </svg>
    `,

    ckpt: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"> <metadata><?xpacket begin=" " id="W5M0MpCehiHzreSzNTczkc9d"?> <x:xmpmeta xmlns:x="adobe:ns:meta" x:xmptk="Adobe XMP Core 5.6-c142 79.160924, 2017/07/13-01:06:39 "> <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"> <rdf:Description rdf:about=""/> </rdf:RDF> </x:xmpmeta> <?xpacket end="w"?></metadata> <defs> <style> .cls-1 { fill-rule: evenodd; } </style> </defs> <path id="comp" class="cls-1" d="M4,28V4H28V28H4Zm8-2h4V18H12v8Zm0-10h4V13H12v3ZM10,6H6v5h4V6Zm0,12H6v8h4V18ZM26,6H12v5H26V6Zm0,7H18v3h8V13Z"/> </svg>`,

    // external providers
    myDrive: `
       <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M5,22c-1.1046,0-2-0.8954-2-2V4c0-1.1046,0.8954-2,2-2h14c1.1046,0,2,0.8954,2,2v16c0,1.1046-0.8954,2-2,2H5z M5,20h14v-1H5
            V20z M19,17V4H5v13H19z M9.1,15c-0.3403,0-0.6509-0.1941-0.8-0.5l-1.1-2c-0.0999-0.2917-0.0999-0.6083,0-0.9l2.9-5.1
            c0.1686-0.2878,0.4675-0.4746,0.8-0.5h2.2c0.3602,0.022,0.6911,0.2058,0.9,0.5l3,5.1c0.0999,0.2917,0.0999,0.6083,0,0.9l-1.1,2
            c-0.1684,0.2879-0.4674,0.4747-0.8,0.5H9.1z M10.2,12.1h3.7l-1.8-3.2L10.2,12.1z"/>
        </svg>
    `,
    myFilesOneDrive: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path fill="#0C0C0C" d="M20.536,19.5H3.48c-0.1966,0.0016-0.3913-0.0375-0.572-0.115c-0.3474-0.15-0.6246-0.4268-0.775-0.774
            c-0.0775-0.1803-0.117-0.3747-0.116-0.571L2,5.96C1.9984,5.7638,2.0376,5.5693,2.115,5.389C2.2652,5.0419,2.5425,4.7653,2.89,4.616
            C3.0673,4.5399,3.2581,4.5005,3.451,4.5h7.182c0.2593-0.0016,0.5164,0.0488,0.756,0.148c0.227,0.0958,0.4381,0.2256,0.626,0.385
            c0.1854,0.158,0.3531,0.3357,0.5,0.53c0.1435,0.1892,0.277,0.3858,0.4,0.589v0.005c0.084,0.142,0.161,0.271,0.238,0.382
            c0.0644,0.0932,0.1419,0.1768,0.23,0.248c0.082,0.065,0.1747,0.1151,0.274,0.148c0.1297,0.0384,0.2648,0.0559,0.4,0.052h6.463
            c0.1966-0.0009,0.3913,0.0386,0.572,0.116c0.3475,0.1493,0.6248,0.4259,0.775,0.773c0.0774,0.1804,0.1169,0.3747,0.116,0.571
            l0.016,9.59c0.0018,0.1966-0.0374,0.3914-0.115,0.572c-0.1507,0.347-0.4278,0.6237-0.775,0.774
            c-0.177,0.0757-0.3675,0.1149-0.56,0.115L20.536,19.5z M4.016,17.5H20L19.985,9H13c-0.13,0-0.228,0.06-0.52,0.282
            C11.9718,9.7284,11.3232,9.9825,10.647,10H4.006L4.016,17.5z M10.023,8h0.624c0.2,0,0.314-0.077,0.625-0.312l0.159-0.119
            c-0.09-0.138-0.172-0.275-0.247-0.4l-0.037-0.064c-0.071-0.113-0.148-0.225-0.229-0.332c-0.0588-0.0768-0.1259-0.1469-0.2-0.209
            c-0.0273-0.0236-0.0575-0.0434-0.09-0.059H4v1.5L10.023,8z"/>
        </svg>
    `,

    // google drive:
    sharedWithMe: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M15,8c-0.0183-1.3727-0.4737-2.7038-1.3-3.8C14.1228,4.0771,14.5598,4.0099,15,4c2.2091,0,4,1.7909,4,4s-1.7909,4-4,4
            c-0.4066-0.0178-0.8096-0.0849-1.2-0.2h-0.1C14.5263,10.7038,14.9817,9.3727,15,8z M16.7,13.1c1.3563,0.8476,2.2146,2.3029,2.3,3.9
            v3h4v-3C23,14.8,19.4,13.5,16.7,13.1z M9,6C7.8954,6,7,6.8954,7,8s0.8954,2,2,2s2-0.8954,2-2C10.9967,6.8968,10.1032,6.0033,9,6
            M9,15c-2.7,0-5.8,1.3-6,2v1h12v-1C14.8,16.3,11.7,15,9,15 M9,4c2.2091,0,4,1.7909,4,4s-1.7909,4-4,4s-4-1.7909-4-4
            C5.0066,5.7936,6.7936,4.0066,9,4z M9,13c2.7,0,8,1.3,8,4v3H1v-3C1,14.3,6.3,13,9,13z"/>
        </svg>
    `,
    sharedWithMeOneDrive: `
        <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M12,22.5c-0.0028-2.4841-2.0159-4.4972-4.5-4.5c-2.4841,0.0028-4.4972,2.0159-4.5,4.5c0,0.5523-0.4477,1-1,1s-1-0.4477-1-1
            c0.002-2.3908,1.3152-4.588,3.42-5.722C3.5135,15.9289,2.9993,14.7421,3,13.5C3.0028,11.0159,5.0159,9.0028,7.5,9
            c1.2421-0.0007,2.4289,0.5135,3.278,1.42c0.6054-1.1185,1.5235-2.0366,2.642-2.642C12.5135,6.9289,11.9993,5.7421,12,4.5
            c0.0028-2.4841,2.0159-4.4972,4.5-4.5c2.4841,0.0028,4.4972,2.0159,4.5,4.5c0.0004,1.2417-0.5137,2.4281-1.42,3.277
            c2.1051,1.1342,3.4183,3.3318,3.42,5.723c0,0.5523-0.4477,1-1,1s-1-0.4477-1-1c-0.0028-2.4841-2.0159-4.4972-4.5-4.5
            c-2.4841,0.0028-4.4972,2.0159-4.5,4.5c0.0004,1.2417-0.5137,2.4281-1.42,3.277c2.1051,1.1342,3.4183,3.3318,3.42,5.723
            c0,0.5523-0.4477,1-1,1S12,23.0523,12,22.5z M5,13.5C5,14.8807,6.1193,16,7.5,16s2.5-1.1193,2.5-2.5S8.8807,11,7.5,11
            S5,12.1193,5,13.5z M14,4.5C14,5.8807,15.1193,7,16.5,7S19,5.8807,19,4.5S17.8807,2,16.5,2S14,3.1193,14,4.5z"/>
        </svg>
    `,
    sharedDrive: `
       <?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
            height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path d="M19,22H5c-1.1046,0-2-0.8954-2-2v-2h18v2C21,21.1046,20.1046,22,19,22z M21,17H3V4c0-1.1046,0.8954-2,2-2h14
            c1.1046,0,2,0.8954,2,2V17z M9,6.2c-2.0987,0-3.8,1.7013-3.8,3.8l0,0c0,2.0987,1.7013,3.8,3.8,3.8l0,0
            c1.6951-0.0118,3.1713-1.1599,3.6-2.8H16v2h2v-2h1V9h-6.4C12.1065,7.4037,10.6687,6.2854,9,6.2z M9,11.7
            c-0.9289-0.0237-1.6763-0.7711-1.7-1.7C7.3237,9.0711,8.0711,8.3237,9,8.3c0.9289,0.0237,1.6763,0.7711,1.7,1.7
            C10.6763,10.9289,9.9289,11.6763,9,11.7z"/>
        </svg>
    `
};
/* eslint-enable */
