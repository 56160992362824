import { utils } from '../../lib';
import { request } from '../../base/axios';
import pushNotifier from '../../base/push-notifier';
import { toast } from '../../toast';
import { progressTracker } from '../../progress/progress';
import { GROUP_ASSET_JOB_ACTIONS } from '../constants';

const ExportPresentationStatus = {
    init: {
        completed: false,
        name: 'init',
        text: gettext('Initializing export'),
        icon: 'icon icon-status-pending-16'
    },
    prepare: {
        completed: false,
        name: 'prepare',
        text: gettext('Preparing export'),
        icon: 'icon icon-status-pending-16'
    },
    progress: {
        completed: false,
        name: 'progress',
        text: gettext('Exporting ...'),
        icon: false
    },
    done: {
        completed: true,
        name: 'complete',
        text: gettext('Done'),
        icon: 'icon icon-status-ok-16'
    },
    failed: {
        completed: true,
        name: 'failed',
        text: gettext('Failed'),
        icon: 'icon icon-status-warning-16'
    }
};

class ExportPresentationProcess {
    constructor (job) {
        this.id = job.id;
        this.name = job.presentation.title;
        this.download_url = ko.observable(job.download_url);
        this.type = 'offline';
        this.icon = 'icon icon-presentation';
        this.dateSubmitted = utils.formatDateTime(job.date_created);

        this.status = ko.observable(ExportPresentationStatus[job.state]);
        this.percentComplete = ko.observable(0);
        this.progress = ko.pureComputed(() => this.percentComplete() + '%');
        this.isCompleted = ko.pureComputed(() => this.status().completed);
        this.lastUpdate = ko.observable('');

        this.error = ko.observable(this.status().name === 'failed');
        this.canceled = ko.observable(this.status().name === 'canceled');
        this.canCancel = () => false;
        this.sequenceNumber = ko.observable(-1);
    }

    start () {
        this.status(ExportPresentationStatus.init);
        this.percentComplete(0);
        toast('PRESENTATION_EXPORT_START');
    }

    update (notification) {
        const { state, progress } = notification;
        this.status(ExportPresentationStatus[state]);
        progress && this.percentComplete(progress);
    }

    download () {
        if (!document.hidden) {
            const a = document.createElement('a');
            a.download = this.name;
            a.href = this.download_url();
            a.dispatchEvent(new window.MouseEvent('click'));
        }
    }

    finish (notification) {
        this.update(notification);
        const { download_url } = notification;
        toast('PRESENTATION_EXPORT_DONE');
        this.download_url(download_url);
        this.download();
    }

    setErrorStatus () {
        this.status(ExportPresentationStatus.failed);
        this.error(true);
        toast('PRESENTATION_EXPORT_FAIL');
    }

    view () {
        this.download();
    };
}

const MakeSlidesJobStatus = {
    init: {
        completed: false,
        name: 'init',
        text: gettext('Creating slides'),
        icon: 'icon icon-status-pending-16'
    },
    prepare: {
        completed: false,
        name: 'prepare',
        text: gettext('Creating slides'),
        icon: false
    },
    done: {
        completed: true,
        name: 'complete',
        text: gettext('Done'),
        icon: 'icon icon-status-ok-16'
    },
    failed: {
        completed: true,
        name: 'failed',
        text: gettext('Failed'),
        icon: 'icon icon-status-warning-16'
    }
};

class MakeSlidesJobProcess {
    constructor (job) {
        this.id = job.id;
        this.name = job.presentation.title;
        this.type = 'asset-group';
        this.icon = 'icon icon-presentation';
        this.dateSubmitted = utils.formatDateTime(job.date_created);
        this.presentationId = job.presentation.uuid;

        this.status = ko.observable(MakeSlidesJobStatus[job.state]);
        this.isCompleted = ko.pureComputed(() => this.status().completed);
        this.progress = ko.observable('');
        this.percentComplete = ko.pureComputed(() => this.isCompleted() ? 100 : 0);
        this.lastUpdate = ko.observable('');
        this.error = ko.observable(this.status().name === 'failed');
        this.canceled = ko.observable(this.status().name === 'canceled');
        this.canCancel = () => false;
        this.sequenceNumber = ko.observable(-1);
    }

    start () {
        this.status(MakeSlidesJobStatus.init);
        this.percentComplete(0);
        toast('PRESENTATION_MAKE_SLIDES_START');
    }

    update (notification) {
        const { state } = notification;
        this.status(MakeSlidesJobStatus[state]);
    }

    finish (notification) {
        this.update(notification);
        notification?.action_results?.make_slide.length && toast('PRESENTATION_MAKE_SLIDES_DONE');
    }

    setErrorStatus () {
        this.status(MakeSlidesJobStatus.failed);
        this.error(true);
        toast('PRESENTATION_MAKE_SLIDES_FAIL');
    }

    view () {
        import('../../vendor/ko-component-router').then((Router) =>
            Router.default.update(`${Settings.PORTAL_PREFIX}presentation/${this.presentationId}/`)
        );
    }
}

const processBuilder = {
    assetGroupProcessBuilders: {
        [GROUP_ASSET_JOB_ACTIONS.MAKE_SLIDE]: function (data) {
            return new MakeSlidesJobProcess(data);
        }
    },
    processBuilders: {
        offline: function (data) {
            return new ExportPresentationProcess(data);
        },
        'asset-group': function (data) {
            for (const action of data.actions) {
                const builder = this.assetGroupProcessBuilders[action];
                if (builder) {
                    return builder.call(this, data);
                }
            }
        }
    },
    build (data) {
        const builder = this.processBuilders[data.type];
        return builder && builder.call(this, data);
    }
};

const api = {
    getJobs: function () {
        return request({
            url: Settings.API_V1_ROOT + 'iboards/jobs/',
            method: 'GET'
        })
            .then(data => data.map(d => processBuilder.build(d)).filter(p => !!p));
    }
};

function loadJobs () {
    api.getJobs(processes => {
        processes.forEach(process => {
            progressTracker.add(process);
        });
    });
}

function trackPresentationJobs () {
    loadJobs();
    pushNotifier.listen('iboards', 'v1').subscribe(({ event, data }) => {
        const eventType = event.split('.')[0];
        if (['offline', 'asset-group'].includes(eventType)) {
            const process = progressTracker.get(data.job.id);
            if (process) {
                if (process.sequenceNumber() >= data.sequence_number) {
                    return;
                }
                process.sequenceNumber(data.sequence_number);
                data.job.state === 'done'
                    ? process.finish(data.job)
                    : process.update(data.job);
            } else {
                progressTracker.add(processBuilder.build(data.job));
            }
        }
    });
}

export {
    api,
    ExportPresentationStatus,
    ExportPresentationProcess,
    MakeSlidesJobStatus,
    MakeSlidesJobProcess,
    trackPresentationJobs
};
