import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import { FATAL_ERRORS } from './texts';

function FatalError ({ dialog, errorType }) {
    const onClose = () => dialog.dismiss();

    return (
        <Dialog>
            <Dialog.Header
                title={gettext('Copying failure')}
                onClose={onClose}
            />

            <Dialog.Inner>
                <div className='di-block'>{ FATAL_ERRORS[errorType] || FATAL_ERRORS.DEFAULT }</div>
            </Dialog.Inner>

            <Dialog.Buttons>
                <Button variant='primary' onClick={onClose}>{ gettext('OK') }</Button>
            </Dialog.Buttons>
        </Dialog>
    );
}

FatalError.propTypes = {
    dialog: PropTypes.any.isRequired,
    errorType: PropTypes.oneOf(Object.keys(FATAL_ERRORS))
};

export default FatalError;
