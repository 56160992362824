class StreamParser {
    constructor () {
        this.currentIndex = 0;
        this.chunk = '';
    }

    loadMore = chunk => {
        this.chunk = chunk;
    };

    next = () => {
        const endIndex = this.chunk.indexOf('\n', this.currentIndex + 1);

        if (endIndex < 0) {
            return false;
        }
        const chunk = this.chunk.substring(this.currentIndex, endIndex);

        let parsed;
        try {
            parsed = JSON.parse(chunk);
        } catch (error) {
            parsed = false;
        }

        this.currentIndex = endIndex;
        return parsed;
    };
}

export { StreamParser };
