import { request } from '../../base/axios';

const copyJobRoot = `${Settings.API_ROOT}copy_to_my_storage/`;

export function loadActiveJobs () {
    return request({
        url: copyJobRoot,
        method: 'GET'
    });
}

export function copyToMyStorage (assets, destinationFolder) {
    return request({
        url: copyJobRoot,
        method: 'POST',
        data: {
            resource_ids: assets.map(asset => asset.forAssetField()),
            dest_folder: destinationFolder.forAssetField()
        }
    });
}

export function copyToMyStorageByUuid (uuid) {
    return request({
        url: `${copyJobRoot}?job_uuid=${uuid}`,
        method: 'POST'
    });
}

export function deleteCopyJob (uuid) {
    return request({
        url: `${copyJobRoot}?job_uuid=${uuid}`,
        method: 'DELETE'
    });
}
