class CopyJobProgress {
    static create (data) {
        const job = new CopyJobProgress(data);
        return job;
    }

    static nodeId (id) {
        return 'copy-job-progress__' + id;
    }

    constructor (message) {
        this.type = 'job.copy'; // Required in the dialog-progress template
        this.id = message.uuid; // Required by ProgressTracker
        this.name = 'Copy shared items to my cloud'; // Sorting columns in the dialog need NAME

        this.message = ko.observable(message);

        this.percentComplete = ko.pureComputed(() =>
            this.message().progress ||
            this.status().completed
                ? 100
                : 0
        ); // Global progress widget requires it
        this.sequenceNumber = ko.pureComputed(() => this.message().sequence_number);
        this.status = ko.pureComputed(() => this.message().state); // Global progress widget requires it (status().name === 'failed')
        this.isCompleted = ko.pureComputed(() => this.status().completed);

        this.what = 'job.copy'; // Required in the dialog-progress template
    }
}

ko.components.register('copy-job', {
    template: `<div
        data-bind="react: {
            component: 'CopyJob',
            props: {
                message: item.message(),
                mobile: mobile,
                dialogDismiss: dialogDismiss
            }
        }"></div>`
});

export default CopyJobProgress;
