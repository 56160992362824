import { request } from '../base/axios';

let integrations = null;

function loadIntegrations () {
    return request({
        url: Settings.INTEGRATIONS_API_URL + 'all/'
    })
        .then(data => {
            integrations = data
                .filter(integration => (
                    integration.type !== 'storage' ||
                    (integration.type === 'storage' && integration.provider !== Settings.DEFAULT_STORAGE)
                ));
            return integrations;
        }, () => {
            integrations = [];
            return integrations;
        });
}

export default {
    get: () => {
        return integrations
            ? Promise.resolve(integrations)
            : loadIntegrations();
    },
    hasIntegrationWith: provider => integrations &&
        integrations.filter(i => i.provider === provider).length !== 0
};
