import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorList from './Dialogs/ErrorList';
import { utils } from '../../lib';
import { deleteCopyJob } from './api';

const JobState = {
    INIT: {
        completed: false,
        name: 'init',
        text: gettext('Initializing'),
        icon: 'icon icon-status-pending'
    },
    PREPARE: {
        completed: false,
        name: 'prepare',
        text: gettext('Preparing'),
        icon: 'icon icon-status-pending'
    },
    PROGRESS: {
        completed: false,
        name: 'progress',
        text: gettext('Downloading ...'),
        icon: 'icon icon-status-pending'
    },
    DONE: {
        completed: true,
        name: 'done',
        text: gettext('Done'),
        icon: 'icon icon-status-ok-16'
    },
    FAILED: {
        completed: true,
        name: 'failed',
        text: gettext('Failed'),
        icon: 'icon icon-status-warning'
    },
    CANCELLED: {
        completed: true,
        name: 'cancelled',
        text: gettext('Cancelled'),
        icon: 'icon icon-status-warning'
    }
};

function CopyJob ({ message, mobile, dialogDismiss }) {
    const [expanded, setExpanded] = useState(false);
    const info = {
        [gettext('Started at:')]: utils.formatDateTime(message.date_created),
        [gettext('Last update:')]: utils.formatDateTime(message.date_modified)
    };

    const expandInfo = () => {
        setExpanded(!expanded);
    };

    const hasErrors = () => {
        return message.errors && Object.keys(message.errors).length > 0;
    };

    const renderErrors = () => {
        return (
            <div>
                <span className='error'>{ gettext('Some files failed to copy')}</span>
                <div style={{ maxHeight: 160, overflow: 'auto' }}>
                    <ErrorList errors={message.errors} />
                </div>
            </div>
        );
    };

    // When editing this, edit dialog-progress-status-info, too
    const renderExpandableSection = () => {
        return (
            <section
                role='alert'
                aria-label='errors info'
                className={`job-info__info__section expandable--vert ${expanded ? 'expanded' : ''}`}
            >
                <div className='job-info__info__table'>
                    { Object.entries(info).map(([title, value]) => (
                        <div key={title} className='job-info__row'>
                            <div className='job-info__row__title'>{title}</div>
                            <div className='job-info__row__content'>{value}</div>
                        </div>
                    ))}
                </div>

                { hasErrors() ? renderErrors() : null }
            </section>
        );
    };

    const renderInfoToggler = () => {
        return (
            <span
                role='button'
                aria-label='expand errors'
                aria-expanded={!!expanded}
                aria-pressed={!!expanded}
                className={`icon icon-info-16 ${hasErrors() ? 'text-color--yellow' : ''} ${expanded ? 'active' : ''}`}
                onClick={ expandInfo }
            />
        );
    };

    const renderViewResult = () => {
        if (message.state === JobState.DONE) {
            const destinationFolderURL = window.encodeURI(
                message.destFolder
                    ? `/portal/files/s3/${Settings.user.login}/${message.destFolder}/`
                    : '/portal/files/');

            const commonProps = {
                role: 'button',
                'aria-label': 'open folder',
                title: gettext('Open folder'),
                className: 'icon icon16 no-decoration icon-eye-16'
            };

            return destinationFolderURL !== window.location.pathname
                ? <a {...commonProps} href={ destinationFolderURL } />
                : <span {...commonProps} onClick={ dialogDismiss } />;
        }
        return null;
    };

    const renderCancelJob = () => {
        return [JobState.INIT, JobState.PREPARE].includes(message.state)
            ? <a
                role='button'
                aria-label='cancel copy job'
                title={gettext('Cancel job')}
                className='icon icon16 no-decoration icon-x-16'
                onClick={ () => deleteCopyJob(message.uuid) }
            />
            : null;
    };

    const renderDesktop = () => {
        return (
            <React.Fragment>
                <div
                    className='progress-ls-item progress-ls-file'
                    data-testid='progress-job--copy'
                    data-what='job'
                    data-which={`copy:${message.uuid}`}
                >
                    <span className='icon job-icon icon-duplicate' />

                    <div className='name' title='name'>
                        <span className='duo-attr name-text'>
                            <span>{ gettext('Copy to Home') }</span>
                        </span>
                    </div>

                    <div className='attr attr-primary size' title='size'>{message.size}</div>
                    <div className='attr attr-primary status' title='status'>
                        {
                            message.state.icon
                                ? <span className={message.state.icon} />
                                : null
                        }
                        <span className='status-text'>{message.state.text}</span>
                    </div>
                    <div className='attr attr-primary icon-column'>
                        <span className='icons-wrap'>
                            { renderViewResult() }
                            { renderInfoToggler() }
                            { renderCancelJob() }
                        </span>
                    </div>

                    { !message.state.completed && <div data-testid='line-loading' className='line-loading line-loading--bot' /> }
                </div>
                { renderExpandableSection() }
            </React.Fragment>
        );
    };

    const renderMobile = () => {
        return (
            <React.Fragment>
                <div className='progress-xs-item' data-testid='progress-job--copy--mobile'>
                    <span className='icon job-icon'>
                        <span className='icon icon-circle icon-duplicate' />
                    </span>

                    <div className='job-info-wrapper'>
                        <div className='file-info-wrapper'>
                            <span className='attr attr-primary name'><span> {gettext('Copy to Home') }</span></span>
                            <span className='attr size'>{message.size}</span>
                        </div>

                        <div className='status'>

                            {
                                message.state.icon
                                    ? <span className={'status-icon ' + message.state.icon} />
                                    : null
                            }
                            <span className='status-text'>{message.state.text}</span>
                            <span className='percent'>{message.progress}</span>
                        </div>
                    </div>

                    <div className='icon-column'>
                        <span className='icons-wrap'>
                            { renderViewResult() }
                            { renderInfoToggler() }
                            { renderCancelJob() }
                        </span>
                    </div>

                    { !message.state.completed && <div data-testid='line-loading' className='line-loading line-loading--bot' /> }
                </div>
                { renderExpandableSection() }
            </React.Fragment>
        );
    };

    return mobile ? renderMobile() : renderDesktop();
};

CopyJob.propTypes = {
    message: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        state: PropTypes.shape({
            completed: PropTypes.bool.isRequired,
            text: PropTypes.string.isRequired,
            icon: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool
            ]).isRequired
        }),
        size: PropTypes.string,
        progress: PropTypes.number,
        errors: PropTypes.object,
        destFolder: PropTypes.string
    }),
    mobile: PropTypes.bool,
    dialogDismiss: PropTypes.func
};

export default CopyJob;
export {
    JobState
};
