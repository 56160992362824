import React from 'react';
import PropTypes from 'prop-types';

import { FileTypes } from '../../../lib/file';
import { DialogThumbsLoader } from '../../../iboards/common/ThumbsLoader';

export function ErrorItem ({ filename }) {
    return (
        <div className='di-list-item' data-testid='copy-job__error-item' >
            <DialogThumbsLoader iconHTML={FileTypes.get(filename).defaultFileTypeIcon()} />
            <div className='item-name'>{filename}</div>
        </div>
    );
}

ErrorItem.propTypes = {
    filename: PropTypes.string.isRequired
};

export default function ErrorList ({ errors }) {
    return (
        <React.Fragment>
            { Object.keys(errors).map((filename, index) => <ErrorItem key={index} filename={filename} />) }
        </React.Fragment>
    );
}

ErrorList.propTypes = {
    errors: PropTypes.object.isRequired
};
