import { Dialog } from '../base';
import { progressTracker } from './progress';
import { PreviousJob } from '../job/job';
import { jobApi } from '../job/api';

const SCROLL_OFFSET = 10;

const sortByNameAscending = function (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

const sortByNameDescending = function (a, b) {
    return -1 * sortByNameAscending(a, b);
};

class ProgressDialog {
    static open () {
        // TODO: Make it reuse the component
        // if (ProgressDialog.instance) {
        //     ProgressDialog.instance.open();
        // } else {
        //     ProgressDialog.instance = Dialog.open({component: 'dialog-progress', ctx: {}});
        // }
        Dialog.open({ component: 'dialog-progress', ctx: {} });
    }

    constructor (params) {
        this.ctx = params.ctx || {};
        this.dialog = params.dialog;
        this.previousJobFactory = this.ctx.previousJobFactory ||
            ((...args) => new PreviousJob(...args));

        this.progressTracker = progressTracker;
        this.loading = progressTracker.loading;
        this.compareFunction = ko.observable(this.sortByNameAscending);
        this.sortedAscending = ko.observable(true);
        this.items = ko.pureComputed(function () {
            const items = Array.from(progressTracker.items());
            items.sort(this.compareFunction());
            return items;
        }, this);
        this.isEmpty = this.progressTracker.isEmpty;
        this.previousJobs = ko.observableArray([]);
        this.activeTab = ko.observable(true);
        this.hasPreviousJobs = ko.observable(false);
        this.loadingPrevious = ko.observable(false);
        this.hasLoadedPrevious = ko.observable(false);
        this.page = ko.observable({ hasNext: false });

        this.activeTab.subscribe(activeTab => {
            if (!activeTab && !this.hasLoadedPrevious()) {
                this.getPreviousJobs();
            }
        });
    }

    openActiveJobs () {
        this.activeTab(true);
    }

    openPreviousJobs () {
        this.activeTab(false);
    }

    getPreviousJobs () {
        this.loadingPrevious(true);
        this.hasLoadedPrevious(true);
        this.paginator = jobApi.getPreviousJobsPaginator();
        this.paginator.then(page => {
            this.page(page);
            this.hasPreviousJobs(this.page().current.length > 0);
            this.loadingPrevious(false);
            this.previousJobs([]);
            this.page().current.map(j => this.previousJobs.push(this.previousJobFactory(j)));
        });
    }

    handlePreviousScrollEvent (_, event) {
        const el = event.target;
        const scrolledToBottom = (el.scrollHeight - el.scrollTop - SCROLL_OFFSET) <= el.clientHeight;
        if (scrolledToBottom && !this.activeTab() && this.page().hasNext && !this.loadingPrevious()) {
            this.loadMorePrevious();
        }
    }

    loadMorePrevious () {
        this.loadingPrevious(true);
        this.page().next().then(page => {
            this.page(page);
            this.loadingPrevious(false);
            this.page().current.map(j => this.previousJobs.push(this.previousJobFactory(j)));
        });
    }

    sortByName () {
        this.sortedAscending(!this.sortedAscending());
        this.compareFunction(this.sortedAscending() ? sortByNameAscending : sortByNameDescending);
    }

    close () {
        this.dialog.dismiss();
    }

    clearFinished () {
        this.items()
            .forEach(j =>
                j.status().completed &&
                j.job &&
                !this.previousJobs().find(e => e.id === j.id) &&
                    this.previousJobs.push(
                        this.previousJobFactory(Object.assign({}, j.job.data, { status: j.status().name }))
                    )
            );
        this.previousJobs(this.previousJobs().sort((x, y) => x.id < y.id ? 1 : x.id === y.id ? 0 : -1));
        progressTracker.clearFinished();

        Settings.IN_WEB_VIEW && window.HostNotification.event('progress_clear_finished').send();
    }
}

export default ProgressDialog;
