import pubsub from './pubsub';

const keyEventsStack = [];

function handleKeyDown (event) {
    const keyEvents = (keyEventsStack[keyEventsStack.length - 1] || { events: {} }).events;

    for (const key in keyEvents) {
        if (keyEvents[key].predicate &&
                keyEvents[key].predicate(event) &&
                (event.target.getAttribute('type') || event.target.type) !== 'text' &&
                event.target.getAttribute('contenteditable') !== 'true') {
            event.preventDefault();
            keyEvents[key].handler(event);
            return;
        }
    }
}

class KeyEvents {
    constructor (events = {}) {
        this.events = extendObject(null, events);
    }

    extend (newEvents) {
        this.events = extendObject(this.events, newEvents);
    }

    reduce () {
        this.events = Object.getPrototypeOf(this.events) || new KeyEvents().events;
    }
}

function pushKeyEvents (events) {
    keyEventsStack.push(new KeyEvents(events));
    pubsub.publish('keyboard.shallow', peekKeyEvents());
}

function peekKeyEvents () {
    const keyEvents = keyEventsStack[keyEventsStack.length - 1];
    if (!keyEvents) {
        init();
        return peekKeyEvents();
    }
    return keyEvents;
}

function popKeyEvents () {
    keyEventsStack.pop();
    pubsub.publish('keyboard.shallow', peekKeyEvents());
}

function init () {
    keyEventsStack.length = 0;
    document.removeEventListener('keydown', handleKeyDown);
    document.addEventListener('keydown', handleKeyDown);
    pushKeyEvents(new KeyEvents());
}

function extendObject (x, y) {
    const z = Object.create(x);
    Object.assign(z, y);
    return z;
}

export default {
    init,
    pushKeyEvents,
    popKeyEvents,
    peekKeyEvents,
    handleKeyDown
};
