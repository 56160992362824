class FeatureSet {
    static hasFeature (featureType) {
        return Settings.user.vcs_features.flags.includes(featureType.full_chain()) ||
            Settings.user.vcs_features.flags.some(
                feature => feature.endsWith('*') &&
                featureType.full_chain().startsWith(feature.split('*')[0])
            );
    }

    static getValue (featureType) {
        return Settings.user.vcs_features.values[featureType];
    }
}

class FeatureType {
    static fromFeatureType (FeatureType) {
        return FeatureType.all[FeatureType];
    }

    constructor (featureType, featureExtension = '') {
        this.featureType = featureType;
        this.chain = featureExtension;
    }

    extend (featureExtension) {
        const chain = this.chain + '.' + featureExtension;
        return new FeatureType(this.featureType, chain);
    }

    full_chain () {
        return this.featureType + this.chain;
    }
}

FeatureType.PRESENTATIONS = new FeatureType('presentations');
FeatureType.JOBS = new FeatureType('jobs');
FeatureType.STORAGE = new FeatureType('storage');
FeatureType.MIGRATE = new FeatureType('migrate');
FeatureType.FONTS = new FeatureType('fonts');

FeatureType.all = {
    presentations: FeatureType.PRESENTATIONS,
    jobs: FeatureType.JOBS,
    storage: FeatureType.STORAGE,
    migrate: FeatureType.MIGRATE,
    fonts: FeatureType.FONTS
};

export {
    FeatureSet,
    FeatureType
};
