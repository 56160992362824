import unorm from 'unorm';

import { utils, File, FileTypes, FilePath } from '../lib';
import { StorageProvider } from '../browser/storage-providers';
import { OwnerInfo, SharingInfo } from '../share/api';
import { parseSharedDriveLookup, previewPath as googlePreviewPath, withDriveId } from '../browser/google-shared-drives';
import { previewPath as oneDrivePreviewPath } from '../browser/onedrive-shared-drives';
import svgs from '../base/default-file-images';

const mixins = {
    byStorage: {
        s3: {},
        dropbox: {
            isAvailable () {
                return this.isValid && this.exists;
            }
        },
        google_drive: {}
    },
    byFileType: {}
};

const OWN_FILE_OWNER_INFO = OwnerInfo.own();

class Asset {
    static create (data, {
        isFolder = false, isLatestVersion = true,
        ownerInfo = OWN_FILE_OWNER_INFO, sharingInfo = null,
        assetActions = {},
        extraActions = {}, parent = null
    }) {
        const byStorage = mixins.byStorage[data.storage_type];
        const byFileType = mixins.byFileType[FileTypes.get(data.prefix).type];
        sharingInfo = sharingInfo || SharingInfo.create(data.sharing_info);
        ownerInfo = data.owner_info
            ? data.owner_info instanceof OwnerInfo
                ? data.owner_info
                : OwnerInfo.create(data.owner_info)
            : ownerInfo;
        const asset = utils.mergeDeep(
            new Asset(data, isFolder, isLatestVersion, ownerInfo, sharingInfo, parent),
            byStorage || {},
            byFileType || {}
        );
        asset.init && asset.init();

        // set actions
        const allActions = Object.assign({}, assetActions, extraActions);
        asset.actions = Object
            .keys(allActions)
            .reduce((actions, actionName) => {
                const action = allActions[actionName];
                if (action.isAllowed(asset)) {
                    actions[actionName] = action;
                }
                return actions;
            },
            {});
        asset.actionNames = Object.keys(asset.actions);
        asset.extraActions = extraActions;

        return asset;
    }

    static update (from, modify) {
        const options = Object.assign({}, from);
        const data = Object.assign({}, from.data);
        modify(data, options);
        return Asset.create(data, { ...options, assetActions: from.actions });
    }

    static fromFile (data, options) {
        return Asset.create(data, Object.assign({}, options, { isFolder: false }));
    }

    static fromFolder (data, options) {
        return Asset.create(data, Object.assign({}, options, { isFolder: true }));
    }

    static fromAsset (data, options) {
        return (data.asset_type === 'folder')
            ? Asset.create(data.asset, Object.assign({}, options, { isFolder: true }))
            : Asset.create(data.asset, Object.assign({}, options, { isFolder: false }));
    }

    static fromSharedAsset (data, options) {
        const ownerInfo = OwnerInfo.create(data);
        return Asset.fromAsset(data, Object.assign({}, options, { ownerInfo }));
    }

    static fromSharedFile (data, options) {
        return Asset.fromSharedAsset(
            Object.assign({}, data, { asset: data.file, asset_type: 'file' }),
            options
        );
    }

    static empty (data) {
        const asset = new Asset(data, false, true, OWN_FILE_OWNER_INFO, SharingInfo.create());
        asset.actions = [];
        asset.actionNames = [];
        asset.isEmpty = true;
        return asset;
    }

    static guessFile (data, options) {
        if (data.isEmpty) {
            return Asset.empty(data);
        } else if (data.link) {
            return Asset.fromLink(data, options);
        } else if (data.owner) {
            return Asset.fromSharedFile(data, options);
        } else {
            return Asset.fromFile(data, options);
        }
    }

    static fromLink (data, options) {
        const sharingInfo = new SharingInfo(data);
        const ownerInfo = OwnerInfo.fromLink(data);
        return Asset.fromAsset(data, Object.assign({}, options, { ownerInfo, sharingInfo }));
    }

    static fromSimpleData (data, options) {
        return Asset.create(
            {
                storage_type: data.storage_type,
                prefix: data.path,
                name: FilePath(data.path).name(),
                is_name_valid: true,
                flags: {
                    is_supported: true
                },
                resource_uri: data.resource_uri,
                version_id: '',
                exists: true,
                last_modifie: null,
                file_type: data.file_type
            },
            {
                isFolder: data.is_folder,
                ...options
            }
        );
    }

    static fromStorageProvider (storageType, driveId = null) {
        const asset = Asset.fromSimpleData(
            {
                storage_type: storageType,
                path: withDriveId('', driveId),
                resource_uri: '',
                file_type: '',
                is_folder: true
            }, {}
        );

        if (storageType === 'google_drive') {
            asset.defaultFileTypeIcon = svgs[driveId] || svgs.sharedDrive;
            const name = googlePreviewPath(`driveId_${driveId}`);
            asset.name = name;
            asset.baseName = name;
        } else if (storageType === 'one_drive') {
            asset.defaultFileTypeIcon = svgs[driveId] || svgs.myFilesOneDrive;
            const name = oneDrivePreviewPath(`driveId_${driveId}`);
            asset.name = name;
            asset.baseName = name;
        }

        return asset;
    }

    static folderFromPath (storageType, path, options = {}) {
        return Asset.fromSimpleData(
            {
                storage_type: storageType,
                path,
                resource_uri: '',
                file_type: '',
                is_folder: true
            }, options
        );
    }

    static reassignActions (asset, assetActions) {
        const allActions = Object.assign({}, assetActions);
        asset.actions = Object
            .keys(allActions)
            .reduce((actions, actionName) => {
                const action = allActions[actionName];
                if (action.isAllowed(asset)) {
                    actions[actionName] = action;
                }
                return actions;
            },
            {});
        asset.actionNames = Object.keys(asset.actions);
        return asset;
    }

    constructor (data, isFolder, isLatestVersion, ownerInfo, sharingInfo, parent) {
        this.isFolder = isFolder;
        this.isFile = !isFolder;
        this.isLatestVersion = isLatestVersion;
        this.parent = parent;

        this.data = data;
        this.fileVersionId = data.id;
        this.fileType = FileTypes.fromResourceFileType(data.file_type) ||
            FileTypes.get(data.prefix, this.isFolder);
        this.storageType = data.storage_type;
        this.storageProvider = StorageProvider.fromStorageType(this.storageType);
        this.name = unorm.nfc(data.name);
        this.isNameValid = data.is_name_valid;
        this.flags = data.flags || {};
        this.isSupported = this.flags.is_supported === false
            ? !!this.flags.is_mounted
            : true;
        this.isValid = this.isNameValid && this.isSupported;
        this.prefix = unorm.nfc(data.prefix.replace(/\/$/, ''));
        this.isRoot = parseSharedDriveLookup(this.prefix)[1] === '';

        this.folder = FilePath(this.prefix).folder();
        this.baseName = FilePath(this.prefix).baseName(isFolder);
        this.extension = FilePath(this.prefix).extension(isFolder);
        this.previewPath = FilePath(this.folder, this.storageType).preview();
        this.previewFullName = FilePath(this.prefix, this.storageType).preview();

        this.versionId = data.version_id;
        this.isPendingVersion = data.version_id?.startsWith('FV#PNDG');
        this.exists = data.exists;
        this.lastModified = data.last_modified;
        this.dateModified = utils.formatDateTime(data.last_modified);
        this.size = (isNaN(data.size) || data.size === null) ? 0.0 : parseFloat(data.size);
        if (this.size < 0) {
            this.size = 0;
        }
        this.sizeText = this.isFile ? new File('', '', this.size).formatedSize() : '';
        this.downloadUrl = data.download_url;
        this.resourceUri = data.resource_uri;

        this.thumbnail = data.thumbnail_3d || data.thumbnail || '';
        this.defaultFileTypeIcon = this.fileType.defaultFileTypeIcon();

        this.ownerInfo = ownerInfo;
        this.isOwn = ownerInfo.owner === window.Settings.user.login;
        this.owner = ownerInfo.owner;

        this.sharingInfo = sharingInfo;
        this.isShared = this.sharingInfo.isShared;
        this.sharedWith = this.sharingInfo.sharedWith.map(sw => sw.username).filter(sw => !!sw);

        this.bluebeamSessions = data.bluebeam_sessions;
        this.label = data.label;
        this.srcId = data.src_id;
    };

    isAvailable () { return this.isValid; }

    isViewable () {
        return this.isValid && [
            FileTypes.image,
            FileTypes.video,
            FileTypes.video_360,
            FileTypes.pdf,
            FileTypes.txt,
            FileTypes.panorama,
            FileTypes.vgx,
            FileTypes.vwx,
            FileTypes.vcdoc,
            FileTypes.html,
            FileTypes.heic,
            FileTypes.connect_cad
        ].indexOf(this.fileType.type) >= 0;
    }

    hasAction (action) {
        return this.isValid && !!this.actions[action];
    }

    doAction (action, context = null) {
        return () => this.isValid && this.actions[action].action.call(context, this);
    }

    hasPermission (permission) {
        return this.isOwn || this.ownerInfo.permission.indexOf(permission) >= 0;
    }

    previousVersions (options = {}) {
        const previousVersions = this.data.previous_versions.map(
            version => Asset.fromFile(
                version,
                Object.assign(
                    {
                        isLatestVersion: false,
                        ownerInfo: this.ownerInfo,
                        parent: this
                    },
                    options
                )
            )
        );
        return previousVersions;
    }

    allVersions (options = {}) {
        return (this.parent)
            ? this.parent.allVersions(options)
            : [this].concat(this.previousVersions(options));
    }

    relatedFiles () {
        if (!this._relatedFiles) {
            this._relatedFiles = this.data.related.map(
                file => Asset.fromFile(
                    file,
                    {}
                )
            );
        }
        return this._relatedFiles;
    }

    equals (other) {
        return this.storageType === other.storageType &&
            this.owner === other.owner &&
            this.prefix === other.prefix &&
            this.isFolder === other.isFolder;
    }

    share (sharingInfo) {
        return Asset.update(this, (data, options) => {
            options.sharingInfo = sharingInfo;

            // In case you updated your own permissions
            const myUpdatedPermissions = sharingInfo.sharedWith.find(profile => profile.login === Settings.user.login);
            if (myUpdatedPermissions) {
                options.ownerInfo.permission = myUpdatedPermissions.permissions;
            }
        });
    }

    unshare (removeLinks = false) {
        return Asset.update(this, (data, options) => {
            options.sharingInfo = this.sharingInfo.unshare(removeLinks);
        });
    }

    mount (mountPoint) {
        const ownerInfo = this.ownerInfo;
        return Asset.update(this, (data, options) => {
            options.ownerInfo = ownerInfo.mount(mountPoint);
        });
    }

    mountRealPath () {
        return this.ownerInfo.mountPoint
            ? FilePath(this.prefix).replacePrefix(
                this.ownerInfo.mountPoint.mount_path,
                this.ownerInfo.mountPoint.path)
            : this.prefix;
    }

    forAssetField () {
        return {
            path: this.mountRealPath(),
            provider: this.storageType,
            is_folder: this.isFolder,
            src_id: this.srcId,
            owner: this.owner
        };
    }

    unmount () {
        const ownerInfo = this.ownerInfo;
        return Asset.update(this, (data, options) => {
            options.ownerInfo = ownerInfo.unmount();
        });
    }
}

class AssetId {
    static fromData (data, isFolder = false) {
        return new AssetId(
            data.storage_type,
            Settings.user.login,
            data.prefix,
            data.version_id,
            data.file_type,
            isFolder
        );
    }

    static fromParams (params, isFolder) {
        const getBool = val => {
            return val === 'true'
                ? true
                : val === 'false'
                    ? false
                    : !!val;
        };

        return new AssetId(
            params.storage_type,
            params.owner,
            params.path,
            params.version_id,
            params.file_type,
            isFolder !== undefined
                ? isFolder
                : params.is_folder !== undefined
                    ? getBool(params.is_folder)
                    : false
        );
    }

    static fromAsset (asset) {
        return new AssetId(
            asset.storageType,
            asset.owner,
            asset.prefix,
            asset.versionId,
            asset.fileType.type,
            asset.isFolder
        );
    }

    constructor (storageType, owner, prefix, versionId, fileType, isFolder = false) {
        this.storageType = storageType;
        this.owner = owner;
        this.prefix = unorm.nfc(prefix.replace(/\/$/, ''));
        const filePath = FilePath(this.prefix);
        this.folder = filePath.folder();
        this.name = filePath.name();
        this.versionId = versionId;
        this.isFolder = isFolder;
        this.fileType = FileTypes.fromResourceFileType(fileType) ||
            FileTypes.get(this.prefix, this.isFolder);
    }

    equals (asset) {
        return asset.equals(this);
    }

    asAsset () {
        return Asset.fromSimpleData(
            {
                storage_type: this.storageType,
                path: this.prefix,
                name: this.name,
                file_type: this.fileType.type,
                is_folder: this.isFolder
            },
            {
                ownerInfo: this.ownerInfo
            }
        );
    }

    asParams () {
        return {
            storage_type: this.storageType,
            owner: this.owner,
            path: this.prefix,
            version_id: this.versionId,
            file_type: this.fileType.type,
            is_folder: this.isFolder
        };
    }

    id () {
        return `${this.storageType}.${this.owner}.${this.prefix}.${this.isFolder}`;
    }
}

export {
    Asset,
    AssetId
};
