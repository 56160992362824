import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import ErrorList from './ErrorList';
import { prepareOverrides } from './texts';

function Overrides ({ uuid, items, dialog }) {
    const onCancel = () => { dialog.dismiss(uuid); };
    const onSubmit = () => { dialog.close(uuid); };

    return (
        <Dialog>
            <Dialog.Header
                title={gettext('Warning')}
                onClose={onCancel}
            />

            <Dialog.Inner>
                <div className='di-block'>{ prepareOverrides }</div>
                <ErrorList errors={items.reduce((acc, item) => ({ [item]: '', ...acc }), {})} />
            </Dialog.Inner>

            <Dialog.Buttons>
                <Button variant='primary' onClick={onSubmit}>{ gettext('Continue') }</Button>
                <Button variant='secondary' onClick={onCancel}>{ gettext('Cancel') }</Button>
            </Dialog.Buttons>
        </Dialog>
    );
}

Overrides.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    uuid: PropTypes.string.isRequired,
    dialog: PropTypes.any.isRequired
};

export default Overrides;
