export const SVGs = {
    dropbox: `
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            enable-background="new 0 0 24 24
            xml:space="preserve">
        <path fill="none" d="M0,0h24v24H0V0z"/>
        <path
            fill="#0061FF" d="M6,18.238l6-3.762l6,3.762L12,22L6,18.238z M12,13.287l6-3.762l6,3.762l-6,3.763L12,13.287z M0,13.287
            l6-3.762l6,3.762L6,17.05L0,13.287z M12,5.762L18,2l6,3.762l-6,3.762L12,5.762z M0,5.762L6,2l6,3.762L6,9.524L0,5.762z"/>
        </svg>
    `,
    google_drive: `
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            enable-background="new 0 0 24 24"
            xml:space="preserve"
        >
            <rect fill="none" width="24" height="24"/>
            <path
                fill="#0066DA"
                d="M2.7,18.3l0.9,1.6c0.2143,0.3143,0.4857,0.5857,0.8,0.8L7.7,15H1.1c0.0052,0.3862,0.1084,0.7647,0.3,1.1
                L2.7,18.3z"
            />
            <path
                fill="#00AC47"
                d="M11.5,8.3L8.2,2.6C7.8857,2.8143,7.6143,3.0857,7.4,3.4l-6,10.5c-0.2038,0.3302-0.3079,0.7121-0.3,1.1h6.6
                L11.5,8.3z"
            />
            <path
                fill="#00832D"
                d="M11.5,8.3l3.3-5.7c-0.3302-0.2038-0.7121-0.3079-1.1-0.3H9.3C8.9121,2.2921,8.5302,2.3962,8.2,2.6L11.5,8.3z"
            />
            <path
                fill="#2684FC" d="M15.4,15H7.7l-3.3,5.7c0.3302,0.2038,0.7121,0.3079,1.1,0.3h12.1c0.3879,0.0079,0.7698-0.0962,1.1-0.3
                L15.4,15z"
            />
            <path
                fill="#EA4335"
                d="M18.688,20.719c0.2883-0.2241,0.5301-0.5023,0.712-0.819l0.4-0.7l1.8-3.2
                    c0.2389-0.2942,0.3838-0.6534,0.416-1.031h-6.663L18.688,20.719z"
            />
            <path
                fill="#FFBA00"
                d="M18.6,8.7l-3-5.3c-0.2143-0.3143-0.4857-0.5857-0.8-0.8l-3.3,5.7l3.9,6.7H22
                c-0.0052-0.3862-0.1084-0.7647-0.3-1.1L18.6,8.7z"
            />
        </svg>
    `,
    one_drive: `
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        enable-background="new 0 0 24 24"
        xml:space="preserve"
    >
        <rect
            fill="none"
            width="24"
            height="24"
        />
        <path
            fill="#0364B8"
            d="M9.1526,8.327L9.1526,8.327l5.0375,3.059l3.002-1.281c0.6103-0.2657,1.2678-0.4019,1.932-0.4
            c0.1114,0,0.2209,0.005,0.3294,0.012c-1.0243-4.0668-5.1061-6.5216-9.1168-5.4829C8.5251,4.7032,6.9519,5.842,5.9258,7.427h0.0769
            C7.1148,7.4238,8.2057,7.7355,9.1526,8.327z"
        />
        <path
            fill="#0078D4"
            d="M9.1536,8.327C8.2057,7.7322,7.1125,7.4183,5.9978,7.421H5.9218c-3.3113,0.0416-5.9624,2.7972-5.9214,6.1549
            c0.0149,1.2178,0.3901,2.4031,1.0771,3.4021l4.4379-1.9l1.9724-0.842l4.3965-1.877l2.2959-0.979L9.1536,8.327z"
        />
        <path
            fill="#1490DF"
            d="M19.4535,9.717c-0.1095-0.008-0.2189-0.012-0.3294-0.012c-0.6642-0.002-1.3217,0.1342-1.932,0.4l-3.002,1.28
                l0.8708,0.528l2.8531,1.733l1.2446,0.756l4.2565,2.585c1.2773-2.4013,0.393-5.3978-1.9752-6.693
                c-0.6135-0.3355-1.2909-0.5337-1.9864-0.581L19.4535,9.717z"/>
        <path
            fill="#28A8EA"
            d="M19.1587,14.407l-1.2397-0.756l-2.86-1.734l-0.8698-0.528l-2.2969,0.979l-4.3965,1.877l-1.9724,0.843
            l-4.4379,1.9c1.1207,1.6337,2.9595,2.6073,4.9221,2.606h13.1175c1.7933,0.0004,3.4417-0.9985,4.291-2.6L19.1587,14.407z"
        />
    </svg>`
};
