import React from 'react';
import PropTypes from 'prop-types';

export const prepareErrorAll = gettext('None of the chosen items for copying could be prepared. Action will not proceed.');
export const prepareErrorSome = gettext('The following files could not be prepared for copying and will be skipped.');
export const prepareOverrides = gettext('File(s) with the same name already exist in your folder. Click Continue to overwrite. The previous file(s) will be available from their version history.');

export const FATAL_ERRORS = {
    DEFAULT: gettext('Failed to continue copying the files. Please try again later.'),
    SPAM: gettext('You can only have one running job per destination folder.'),
    CANCEL_RUNNING: gettext('You can\'t cancel a running job.')
};

export function PossibleReasons ({ warning }) {
    return (
        <div className='di-block'>
            <span>{ warning }</span>
            { gettext('Possible reasons:') }
            <ul>
                <li>{gettext('These files are not shared with download permissions')}</li>
                <li>{gettext('Couldn\'t read files from storage provider - Vectorworks Cloud Services, Dropbox or Google Drive')}</li>
                <li>{gettext('The specified destination folder is not found')}</li>
                <li>{gettext('You tried to download empty folder')}</li>
            </ul>
        </div>
    );
}

PossibleReasons.propTypes = {
    warning: PropTypes.string
};
