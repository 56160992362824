import { FilePath, buildPreviewPath } from '../lib';

const JobStatus = {
    preparing: {
        completed: false,
        name: 'preparing',
        text: gettext('Preparing'),
        icon: 'icon icon-status-pending-16'
    },
    ready: {
        completed: false,
        name: 'ready',
        text: gettext('Queued...'),
        icon: false
    },
    queued: {
        completed: false,
        name: 'queued',
        text: gettext('Queued...'),
        icon: false
    },
    scheduled: {
        completed: false,
        name: 'scheduled',
        text: gettext('Queued...'),
        icon: false
    },
    pickedup: {
        completed: false,
        name: 'pickedup',
        text: gettext('Generating file(s)'),
        icon: false
    },
    processing: {
        completed: false,
        name: 'processing',
        text: gettext('Generating file(s)'),
        icon: false
    },
    complete: {
        completed: true,
        name: 'complete',
        text: gettext('File(s) generated'),
        icon: 'icon icon-status-ok-16'
    },
    failed: {
        completed: true,
        name: 'failed',
        text: gettext('Generation failed'),
        icon: 'icon icon-status-warning-16'
    },
    canceled: {
        completed: true,
        name: 'canceled',
        text: gettext('Generation canceled'),
        icon: 'icon icon-status-error-16'
    }
};

const JobIcons = {
    export_pdf: 'icon-export-pdf',
    measure: 'icon-measure',
    distill: 'icon-export-3d',
    photogram: 'icon-photogrammetry',
    extract_style: 'icon-nast',
    panorama: 'icon-panorama',
    publish: 'icon-job-publish',
    generic: 'icon-job-generic',
    animation: 'icon-animation-job',
    upsample: 'icon-upsample',
    style_transfer: 'icon-nast',
    solar_animation: 'icon-job-solar-animation',
    revit_export: 'icon-export-rvt',
    default: 'icon-job-generic'
};

const failedReasons = {
    UPLOAD_FAILED: gettext('Upload failed'),
    DOWNLOAD_FAILED: gettext('Download failed'),
    'dirigible_cannot_download(-1)': gettext('Source file not found'),
    'dirigible_cannot_download(0)': gettext('Source file not found'),
    'dirigible_cannot_download(400)': gettext('Source file not found'),
    'dirigible_cannot_download(403)_Forbidden': gettext('Source file not found'),
    dirigible_cannot_open_not_VWXFile: gettext('Unsupported file version or invalid Vectorworks file'),
    dirigible_cannot_openVWXFile: gettext('Unsupported file version or invalid Vectorworks file'),
    dirigible_cannot_openVWXFile_ver: gettext('This Vectorworks file version is newer than the Vectorworks version on the cloud.'),
    'dirigible_cannot_download(0)_file_not_found_on_s3': gettext('Could not download - file not in storage'),
    dirigible_cannot_render_student_file: gettext('Educational file format'),
    NO_OUTPUT: gettext('Could not generate 3D model from the provided images'),
    could_not_get_result_data: gettext('Could not get result data'),
    access_token_not_valid: gettext('Access token not valid'),
    JOB_TYPE_NOT_SUPPORTED: gettext('Job type not supported'),
    TIMED_OUT: gettext('The job reached its time processing limit'),
    PREPARE_FAILED: gettext('Job preparation failed'),
    source_file_not_found: gettext('Source file not found'),
    internal_error: gettext('Internal error'),
    failed: gettext('Failed'),
    crashed: gettext('Crashed'),
    VW_CRASHED: gettext('Vectorworks crashed'),
    vwinstance_crashed: gettext('Vectorworks crashed'),
    vwinstance_work_timeout: gettext('Vectorworks timed out'),
    'VW deleted': gettext('Vectorworks deleted'),
    NO_3D_GEOMETRY_EXPORTED: gettext('No visible supported geometry'),
    NO_SUFFICIENT_OVERLAP: gettext('Insufficient overlap'),
    LOW_IMAGE_QUALITY: gettext('Insufficient quality'),
    default: gettext('Unknown')
};

const JobLastUpdateStatus = {
    'Generating file(s)': ({ step, from }) => interpolate(gettext('%(step)s/%(from)s Generating file(s)'), { step, from }, true),
    'Saving results': ({ step, from }) => interpolate(gettext('%(step)s/%(from)s Saving results'), { step, from }, true),
    default: ({ step, from, update }) => {
        if (update.includes('Iteration')) {
            const iterationNumber = update.split(' ')[1];
            return interpolate(gettext('%(step)s/%(from)s Iteration %(iterationNumber)s'), { step, from, iterationNumber }, true);
        } else {
            return interpolate(gettext('%(step)s/%(from)s %(update)s'), { step, from, update }, true);
        }
    }
};

const JobLastUpdateStatusList = {
    Starting: gettext('Generating file(s)'),
    'Export Thumbnail': gettext('Generating file(s)'),
    'Export WebView': gettext('Generating file(s)'),
    'Export 3D Scene': gettext('Generating file(s)'),
    'Export VW Metadata': gettext('Generating file(s)'),
    'Generating file(s)': gettext('Generating file(s)'),
    Finished: gettext('Saving results'),
    'Saving results': gettext('Saving results'),
    'Waiting for CineRender': gettext('Generating file(s)'),
    'Waiting for renderer': gettext('Generating file(s)'),
    'Rendering Panorama': gettext('Generating file(s)'),
    '': ''
};

const jobTitles = {
    animation: gettext('Animation'),
    solar_animation: gettext('Solar animation'),
    distill: gettext('Generate 3D model'),
    export_pdf: gettext('Generate PDF'),
    generic: gettext('Generic job'),
    measure: gettext('Measurement'),
    panorama: gettext('Panorama'),
    photogram: gettext('Photos to 3D model'),
    publish: gettext('Publish'),
    upsample: gettext('Upsample image'),
    style_transfer: gettext('Stylize image'),
    revit_export: gettext('Export Revit')
};

const SUPPORTED_JOB_TYPES = ['export_pdf', 'publish', 'distill', 'generic', 'photogram'];

const updateRegx = /(\d+)\/(\d+)\s+(.+)/;
const colonRegx = /(\d+):(\d+):(.+)/;

const handleLastUpdate = update => {
    if (updateRegx.test(update) || colonRegx.test(update)) {
        const regexMatch = updateRegx.test(update)
            ? update.match(updateRegx)
            : update.match(colonRegx);
        const status = {
            step: regexMatch[1],
            from: regexMatch[2],
            update: regexMatch[3]
        };
        return JobLastUpdateStatus[status.update]
            ? JobLastUpdateStatus[status.update](status)
            : JobLastUpdateStatus[JobLastUpdateStatusList[status.update]]
                ? JobLastUpdateStatus[JobLastUpdateStatusList[status.update]](status)
                : JobLastUpdateStatus.default(status);
    } else {
        return JobLastUpdateStatusList[update] || update;
    }
};

const progressFromLastUpdate = update => {
    if (updateRegx.test(update)) {
        const regexMatch = update.match(updateRegx);
        const step = regexMatch[1];
        const from = regexMatch[2];
        return (step * 100) / from;
    } else {
        // Indicates that the job has started, even if there's not much progress on it.
        return 1;
    }
};

// Source of origin: DCC
const getSourcePath = (type, job, sourceProvider) => {
    const storageType = sourceProvider.storageType;
    const opts = job.options || {};

    const srcInfo = Object.keys(opts).includes('src_file_info')
        ? opts.src_file_info
        : {
            file_count: opts.src_file_versions?.length,
            path: opts.src_file_versions?.length ? opts.src_file_versions[0].path : null,
            provider: opts.src_file_versions?.length ? opts.src_file_versions[0]?.provider : null
        };

    switch (type) {
    case 'photogram':
        return job.file_version.fileType === 'DIR'
            ? srcInfo.file_count
                ? FilePath(
                    FilePath(srcInfo.path).folder(),
                    storageType
                ).preview()
                : gettext('Unknown')
            : srcInfo.file_count
                ? FilePath(
                    FilePath(srcInfo.path).folder(),
                    storageType
                ).preview() + ` (${srcInfo.file_count})`
                : gettext('Unknown');
    case 'measure':
        return opts.job_name
            ? buildPreviewPath([
                gettext('Temporary Cloud Storage'),
                opts.job_name
            ])
            : buildPreviewPath([
                gettext('Temporary Cloud Storage'),
                FilePath(
                    opts.output_location.replace('.vwx', '').replace('.pdf', ''),
                    storageType
                ).preview({ ignoreProvider: true })
            ]);
    case 'publish':
        if (srcInfo.file_count) {
            if (srcInfo.provider === 'internal') {
                return buildPreviewPath([
                    gettext('Temporary Cloud Storage'),
                    FilePath(srcInfo.path).name()
                ]);
            } else {
                return FilePath(
                    srcInfo.path,
                    storageType
                ).preview();
            }
        } else {
            return gettext('Unknown');
        }
    case 'animation':
        return srcInfo.file_count
            ? buildPreviewPath([
                gettext('Temporary Cloud Storage'),
                FilePath(srcInfo.path).name()
            ])
            : gettext('Unknown');
    case 'solar_animation':
        return srcInfo.file_count
            ? buildPreviewPath([
                gettext('<Temporary Cloud Storage>'),
                FilePath(srcInfo.path).name()
            ])
            : gettext('Unknown');
    case 'panorama':
        return srcInfo.file_count
            ? buildPreviewPath([
                gettext('Temporary Cloud Storage'),
                FilePath(srcInfo.path).name()
            ])
            : gettext('Unknown');
    case 'distill':
        const srcFile = job.file_version;  // eslint-disable-line
        if (srcFile.provider === 'internal') {
            return buildPreviewPath([
                gettext('Temporary Cloud Storage'),
                FilePath(srcFile.path).name()
            ]);
        } else {
            return FilePath(
                srcFile.path,
                storageType
            ).preview();
        }
    default:
        try {
            // export_pdf, upsample, style_transfer
            return FilePath(
                job.file_version.path,
                storageType
            ).preview();
        } catch (error) {
            return null;
        }
    }
};

export {
    JobStatus,
    JobIcons,
    failedReasons,
    JobLastUpdateStatus,
    JobLastUpdateStatusList,
    jobTitles,
    getSourcePath,
    SUPPORTED_JOB_TYPES,
    handleLastUpdate,
    progressFromLastUpdate
};
