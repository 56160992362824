import { queryString, File, FilePath, Paginator } from '../lib';
import { request } from '../base/axios';
import { StorageProvider } from '../browser/storage-providers';
import { jobTitles, JobIcons, SUPPORTED_JOB_TYPES, getSourcePath } from './utils';

const jobUtils = {
    getPublishJobName (path) {
        const publishJobName = 'Publish Job';
        const match = /Publish Job[^.]+/.exec(path);
        if (match != null) {
            return match[0].replace(publishJobName, gettext(publishJobName));
        }
        return gettext(publishJobName);
    },
    fileName (job) {
        if (job.job_type === 'publish') {
            return this.getPublishJobName(job.file_version.path);
        }
        if (job.job_type === 'photogram') {
            return job.options.output_location.replace(/^\//, '');
        }
        if (job.job_type === 'generic' && job.options.operation === 'measure') {
            return job.options.job_name
                ? job.options.job_name
                : job.options.output_location.replace('.vwx', '').replace('.pdf', '');
        }
        return FilePath(job.file_version.path).name();
    },
    folderName (job) {
        if (job.job_type === 'publish') {
            return job.options.output_location;
        }
        return FilePath(job.file_version.path).folder();
    }
};

class Job {
    constructor (data) {
        this.data = data;
        this.id = data.id;
        this.file = new File(
            jobUtils.fileName(data),
            jobUtils.folderName(data)
        );
        this.type = (data.job_type === 'generic')
            ? data.options.operation
            : data.job_type;
        this.name = jobTitles[this.type];

        this.sourceStorageProvider = this.getSourceStorageProvider();
        this.sourcePath = getSourcePath(this.type, this.data, this.sourceStorageProvider);
        this.sourceFileName = FilePath(this.sourcePath).name().trim();
        this.outputStorageProvider = StorageProvider.fromStorageType(
            this.data?.options?.output_storage_type || this.data.file_version.provider
        );

        this.outputLocation = FilePath(
            this.data?.options?.output_location || '',
            this.outputStorageProvider.storageType
        ).preview();

        this.sequenceNumber = data.sequence_number || 0;
        this.icon = JobIcons[this.type] || JobIcons.default;
        this.isResultAFolder = ['photogram', 'publish'].includes(this.type);
    }

    getResult () {
        const owner = 'output_location_owner' in this.data
            ? this.data.output_location_owner
            : this.data.file_version
                ? this.data.file_version.owner
                : Settings.user.login;
        const { provider } = this.data.file_version;
        const jobOptions = this.data.options;
        return {
            storageType: jobOptions?.output_storage_type || provider,
            prefix: jobOptions?.output_location,
            isFolder: ['publish', 'photogram'].includes(this.type),
            owner,
            isShared: owner !== Settings.user.login
        };
    }

    getSourceStorageProvider () {
        const jobOptions = this.data.options;
        const storageType = ['publish', 'panorama', 'photogram'].includes(this.type)
            ? jobOptions.src_storage_type
            : this.data.file_version.provider;
        return StorageProvider.fromStorageType(storageType) || StorageProvider.S3;
    }
}

const JOB_OPTIONS_FIELDS = [
    'output_location_owner',
    '-options.src_file_versions',
    '-options.ref_file_versions',
    'options.src_file_info',
    'issue_reported'
];

const jobApi = {
    getJob (jobId) {
        return request({
            url: Settings.JOBS_API_URL + jobId + `/?fields=(${JOB_OPTIONS_FIELDS.join(',')})`,
            method: 'GET'
        })
            .then(jobData => new Job(jobData));
    },
    getAllActiveJobs () {
        return Paginator.all(`${Settings.JOBS_API_URL}?current&job_type__in=${SUPPORTED_JOB_TYPES.join(',')}&fields=(${JOB_OPTIONS_FIELDS.join(',')})`);
    },
    cancelJob (job) {
        return request({
            url: Settings.JOBS_API_URL + job.id + '/',
            method: 'DELETE'
        });
    },
    getPreviousJobsPaginator () {
        return Paginator.fromUrl(
            queryString.buildUrl(
                Settings.JOBS_API_URL,
                {
                    job_type__in: SUPPORTED_JOB_TYPES.join(','),
                    status__in: 'complete,canceled,failed',
                    page_size: 20,
                    fields: `(${JOB_OPTIONS_FIELDS.join(',')})`
                }
            )
        );
    }
};

export {
    Job,
    jobApi,
    jobUtils
};
