import { progressTracker } from '../../progress/progress';
import pushNotifier from '../../base/push-notifier';

import CopyJobProgress from './CopyJobProgress';
import { JobState } from './CopyJob';
import AllFailed from './Dialogs/AllFailed';
import Errors from './Dialogs/Errors';
import Overrides from './Dialogs/Overrides';
import FatalError from './Dialogs/FatalError';
import { copyToMyStorageByUuid, deleteCopyJob, loadActiveJobs } from './api';

const CopyJobMessage = {
    fromData: data => {
        const { errors = {}, overrides = [], dest_folder = '', all_failed = false } = data;
        return {
            ...data,
            state: Object.values(JobState).find(s => s.name === data.state),
            errors,
            overrides,
            destFolder: dest_folder,
            allFailed: all_failed
        };
    }
};

function handleAllFailed (uuid, errors) {
    return window.ReactDialogs.open({
        component: AllFailed,
        context: { uuid, errors }
    }).result
        .then(uuid => removeProgressTrackerItem(uuid))
        .catch(uuid => removeProgressTrackerItem(uuid));
}

function handleErrors (uuid, errors) {
    return window.ReactDialogs.open({
        component: Errors,
        context: { uuid, errors }
    }).result;
}

function handleOverrides (uuid, overrides) {
    return window.ReactDialogs.open({
        component: Overrides,
        context: { uuid, items: overrides }
    }).result
        .then(uuid => copyToMyStorageByUuid(uuid))
        .catch(uuid => deleteCopyJob(uuid));
}

function handlePrepareWarnings ({ uuid, state, errors, overrides, allFailed }) {
    const hasErrors = Object.keys(errors).length > 0;
    const hasOverrides = overrides.length > 0;

    if (allFailed) {
        return handleAllFailed(uuid, errors);
    }

    if (state === JobState.PREPARE) {
        if (hasErrors) {
            return handleErrors(uuid, errors)
                .then(uuid => hasOverrides
                    ? handleOverrides(uuid, overrides)
                    : copyToMyStorageByUuid(uuid))
                .catch(uuid => deleteCopyJob(uuid));
        } else {
            if (hasOverrides) {
                return handleOverrides(uuid, overrides);
            }
        }
    }
}

function removeProgressTrackerItem (uuid) {
    const item = progressTracker.items().find(item => item.id === uuid);
    item && progressTracker.remove(item);
}

function handleMessage (data) {
    const jobProcess = progressTracker.get(data.uuid);
    if (jobProcess && jobProcess.sequenceNumber() >= data.sequence_number) {
        return;
    }

    if (data.fatalError) {
        window.ReactDialogs.open({
            component: FatalError,
            context: { errorType: data.fatalError }
        }).result.catch(() => {});

        removeProgressTrackerItem(data.uuid);
        return;
    }

    if (data.state === JobState.CANCELLED.name) {
        removeProgressTrackerItem(data.uuid);
        return;
    };

    const message = CopyJobMessage.fromData(data);
    handlePrepareWarnings(message);
    jobProcess
        ? jobProcess.message(message)
        : progressTracker.add(CopyJobProgress.create(message));
}

async function trackCopyFiles () {
    return loadActiveJobs()
        .then(messages => messages.map(msg => handleMessage(msg)))
        .then(() => pushNotifier.listen('job.copy', 'v1')
            .subscribe(({ data }) => handleMessage(data)));
}

export { trackCopyFiles, CopyJobMessage };
